import { DomHelper } from './utils/dom-helper'

/*
 *
 * Módulo que manipula eventos de requisições ajax de formulários remotos
 *
 *
 *  [construtor]
 *
 *  - aContainer: Objeto jquery que possui formulário
 *
 *
 * Utiliza os seguintes parâmetros:
 *
 *   [data-content=loading]: Loader para exibir durante a requisição ajax
 *
 */
function RemoteForm(aContainer) {
'use strict';

  // globals

  var _domContainer = aContainer,
      _domHelper = new DomHelper(_domContainer);

  // event handlers

  _domContainer.on('ajax:before', function(aEvent) {
    _startLoading($(aEvent.target));
  });

  _domContainer.on('ajax:success', function(aEvent, aData) {
    _showResults(aData);
  });

  _domContainer.on('ajax:remotipartComplete', function(aEvent, aData) {
    _showResults(aData.responseText);
  });

  // privates

  function _startLoading(aContainer) {
    _getLoader(aContainer).removeClass('hidden-xs-up');
  }

  function _stopLoading(aContainer) {
    _getLoader(aContainer).addClass('hidden-xs-up');
  }

  function _getLoader(aContainer) {
    return aContainer.find('[data-content=loading]');
  }

  function _showResults(aData) {
    var container = _getResultsContainer(),
        invalid = _isForm(aData);

    if (invalid) {
      // usamos o _domContainer pois o form está invalidado.
      container = _domContainer;
    }

    _insertData(container, aData);

    container.find('select').select2({allowClear: true});

    _stopLoading(container);

    _domHelper.fireEvent('remote-form:after', { success: ! invalid });
  }

  function _getResultsContainer() {
    var resultsContainerSelector = _domContainer.attr('data-results-container'),
        resultsContainer = $(resultsContainerSelector);

    if (resultsContainer.length === 1) {
      return resultsContainer;
    }

    return _domContainer;
  }

  function _getInsertMethod() {
    var insertMethod = _domContainer.attr('data-insert-method');

    if (insertMethod === undefined) {
      return 'replace';
    }

    return insertMethod;
  }

  function _insertData(aContainer, aData) {
    var insertMethod = _getInsertMethod(),
        container = aContainer;


    if (_isForm(aData)) {
      // ignoramos o insert-method pois form invalidado precisa ser substituído.
      container.html(aData);
    } else {
      if (insertMethod === 'append') {
        container.append(aData);
      } else if (insertMethod === 'prepend') {
        container.prepend(aData);
      } else {
        container.html(aData);
      }

    }

  }

  function _isForm(aData) {
    return ($(aData).find('form').length > 0 && $(aData).find('form').data('invalid') === true);
  }
}

/*
 * Inicializador via data-toggle
 *
 *
 *  [construtor]
 */
$(function() {
'use strict';

  $('[data-toggle=remote-form]').each(function() {
    var remoteFormContainer = $(this),
        remoteForm = RemoteForm(remoteFormContainer);
  });

});
