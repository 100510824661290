/**
 * Componente para busca em listas
 */

import { EventKeyHelper } from './utils/event-key-helper'

export default class ListSearch {

  /* constants */

  static ITEM_SELECTOR = '.form-check, .list-search-item';

  constructor() {


    var _eventKeyHelper = new EventKeyHelper();

    /* event handlers */

    $(document).on('keyup', '[data-toggle=list-search] input[type="text"]', function(aEvent) {
      var event = aEvent,
          keyCode = event.keyCode,
          input = $(this),
          container = input.closest('[data-toggle=list-search]'),
          searchText = input.val().toLowerCase();

      if (_eventKeyHelper.isEscKeyCode(keyCode)) {
        _clearInput(input);
        _showAll(container);
      } else {
        _showFor(container, searchText);
      }
    });

    $(document).on('click', '[list-search-check-all]', function(aEvent) {
      var container = $(this).closest('[data-toggle=list-search]');

      _markAll(container, true);
    });

    $(document).on('click', '[list-search-uncheck-all]', function(aEvent) {
      var container = $(this).closest('[data-toggle=list-search]');

      _markAll(container, false);
    });

    function _clearInput(aInput) {
      aInput.val('');
    }

    function _showAll(aContainer) {
      aContainer.find(ListSearch.ITEM_SELECTOR).each(function() {
        $(this).toggle(true);
      });

      _toogleResultsUl(aContainer, true);
    }

    function _showInitial(aContainer) {
      const input = $(aContainer).find('input[type="text"]')

      if (input.length > 0) {
        const searchText = input.val().toLowerCase();

        _showFor(aContainer, searchText)
      }
    }

    function _showFor(aContainer, aSearchText) {
      var anyResultsFound = false;

      aContainer.find(ListSearch.ITEM_SELECTOR).each(function() {
        var searchText = $(this).attr('data-search-text'),
            currentText = (searchText || '').toLowerCase(),
            found = (searchText === undefined) || currentText.indexOf(aSearchText) !== -1;

        anyResultsFound = anyResultsFound || found;

        $(this).toggle(found);
      });

      _toogleResultsUl(aContainer, anyResultsFound);
    }

    function _toogleResultsUl(aContainer, hasResult) {
      var noResultsContainer = aContainer.find('[data-search-list-no-results]'),
          resultUl = aContainer.find('[data-search-list-ul]');

      noResultsContainer.toggle(! hasResult);
      resultUl.toggle(hasResult);
    }

    function _markAll(aContainer, aChecked) {
      aContainer.find('input[type=checkbox]:visible').prop('checked', aChecked);

      // não precisamos disparar change em todos, pois deixaria lento para vários
      // checkboxes
      aContainer.find('input[type=checkbox]:visible:first').trigger('change');
    }

    function _init() {
      _showInitial($('[data-toggle=list-search]'));
    }

    _init()
  }
}

$(function() {
  let listSearch = new ListSearch();
});
